import { q } from '../../helper/q'

export const mobileMenuInit = () => {
  q('.hamburger').addEventListener('click', () => {
    q('.headerMobileMenu').classList.toggle('headerMobileMenuShow')
    q('.hamburger').classList.toggle('is-active')
    q('.mobileMenu').classList.toggle('mobileMenuShow')
  })
  q('.mobileMenuExpandTrigger').addEventListener('click', () => {
    q('.mobileMenuExpandTrigger').classList.toggle(
      'mobileMenuExpandTriggerOpen'
    )
    q('.mobileMenuExpand').classList.toggle('mobileMenuExpandOpen')
  })
}
