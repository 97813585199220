import 'reseter.css'
import 'swiper'
import '../css/main.css'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import { ProductsSliderInit } from './components/products/slider.js'
import { mainInit } from './components/main/index.js'
import { mobileMenuInit } from './components/header'
import { mapInit } from './components/contact/map-style'
import { q } from './helper/q'
import { feedBackInit } from './components/feedback/trigger'

//
gsap.registerPlugin(ScrollTrigger)

if (q('body').classList.contains('headerTransparent')) {
  // q('body').classList.remove('headerTransparent')
  console.log('sssss')
  ScrollTrigger.create({
    start: 'top -80',
    end: 99999,
    toggleClass: {
      className: 'headerTransparentImportant',
      targets: '.header',
    },
  })
}

document.addEventListener('DOMContentLoaded', () => {
  console.log('sss')
  mapInit()
  mainInit()
  ProductsSliderInit()
  mobileMenuInit()
  feedBackInit()
})
