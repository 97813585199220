import Swiper from 'swiper'

export const ProductsSliderInit = () => {
  let swiper = new Swiper('.productSlider .swiper-container', {
    slidesPerView: 'auto',
    spaceBetween: 25,
    breakpoints: {
      768: {
        slidesPerView: 'auto',
        spaceBetween: 55,
      },
      1280: {
        slidesPerView: 4,
        spaceBetween: 55,
      },
    },
  })
}
