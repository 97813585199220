/* global feedback */
import loadjs from 'loadjs'

import { qq } from '../../helper/q.js'

let isFeedBackLoad = false
export const feedBackInit = () => {
  let buttons = qq('.buttonFeedBack')
  if (!buttons) return null

  buttons.map((button) => {
    button.addEventListener('click', () => {
      if (isFeedBackLoad) {
        feedback.open()
      } else {
        button.disabled = true
        loadjs(`/local/templates/main/js/feedback.js`, () => {
          isFeedBackLoad = true
          feedback.init(button.dataset.product_id).then(() => {
            button.disabled = false
            feedback.open()
          })
        })
      }
    })
  })
}
