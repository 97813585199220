import Swiper from 'swiper'

import { q, qq } from '../../helper/q'

export const mainSliderInit = () => {
  if (!q('.mainSlider')) return null
  let swiper = new Swiper('.mainSlider', {
    slidesPerView: 1,
  })
  let navItems = qq('.mainSliderNavItem')
  navItems.map((item, index) => {
    item.addEventListener('click', () => {
      navItems.map((el) => {
        el.classList.remove('mainSliderNavItemActive')
      })
      item.classList.add('mainSliderNavItemActive')
      swiper.slideTo(index)
    })
  })
}
